import * as React from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import addToMailchimp from "gatsby-plugin-mailchimp";
import CenterWrapper from "./CenterWrapper";

const NewsletterSignUp = ({ locale }: { locale: string }) => {
  function validateEmail(value: string) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  function validatePrivacy(value: boolean) {
    let error;
    if (!value) {
      error = "Please accept the privacy policy";
    }
    return error;
  }

  return (
    <>
      <CenterWrapper>
        <Formik
          initialValues={{
            email: "",
            firstname: "",
            lastname: "",
          }}
          onSubmit={async ({ email, firstname, lastname }, actions) => {
            const result = await addToMailchimp(email, {
              FNAME: firstname,
              LNAME: lastname,
              tags: 7501830,
            });
            actions.setSubmitting(false);
            // I recommend setting `result` to React state
            // but you can do whatever you want
          }}
        >
          {(props) => (
            <Form>
              <Field name="email" validate={validateEmail}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel>E-Mail Adresse</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="firstname">
                {({ field, form }) => (
                  <FormControl>
                    <FormLabel>Vorname</FormLabel>
                    <Input {...field} />
                  </FormControl>
                )}
              </Field>
              <Field name="lastname">
                {({ field, form }) => (
                  <FormControl>
                    <FormLabel>Nachname</FormLabel>
                    <Input {...field} />
                  </FormControl>
                )}
              </Field>
              <Field name="privacy" validate={validatePrivacy}>
                {({ field, form }) => (
                  <FormControl>
                    <Checkbox {...field} /> Ich habe die Datenschutzerklärung
                    gelesen und akzeptiere diese.
                  </FormControl>
                )}
              </Field>
              <Button
                mt={4}
                colorScheme="teal"
                isLoading={props.isSubmitting}
                type="submit"
              >
                Anmelden
              </Button>
            </Form>
          )}
        </Formik>
      </CenterWrapper>
    </>
  );
};

export default NewsletterSignUp;
