import * as React from "react";
import styled from "@emotion/styled";
import Slick from "react-slick";

const SlickWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: block;
  text-align: left;

  .slick-prev,
  .slick-next {
    opacity: 0.33;
  }

  .slick-list {
    transition: height 0.3s ease;
  }

  .chakra-linkbox {
    opacity: 0;
    transition: opacity 2s ease;
  }

  .slick-active {
    .chakra-linkbox {
      opacity: 1;
    }
  }
`;

const Slider = ({
  data,
  locale,
  showNavigation = true,
  slidesPerView = 1,
  loop = true,
  slideElement,
}: {
  data: any[];
  locale?: string;
  showNavigation?: boolean;
  slidesPerView?: number;
  loop?: boolean;
  slideElement: (props: any) => JSX.Element;
}) => {
  const StyledSlide =
    slidesPerView > 1 && data.length > 1
      ? styled.div`
          padding-left: 1em;
          &:first-of-type {
            padding-left: 0;
            padding-right: 0.5em;
          }
          &:nth-of-type(2) {
            padding-left: 0.5em;
          }
        `
      : styled.div``;

  return (
    <SlickWrapper>
      <Slick
        dots={showNavigation}
        infinite={loop}
        speed={500}
        slidesToScroll={1}
        adaptiveHeight
        lazyLoad="progressive"
        slidesToShow={
          data.length >= slidesPerView ? slidesPerView : data.length
        }
      >
        {data &&
          data.length &&
          data
            .reverse()
            .map((element, key) => (
              <StyledSlide key={key}>
                {slideElement({ data: element, locale })}
              </StyledSlide>
            ))}
      </Slick>
    </SlickWrapper>
  );
};

export default Slider;
