import * as React from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import styled from "@emotion/styled";

import CenterWrapper from "./CenterWrapper";

const StyledContactForm = styled.div`
  background-color: #585858;
  box-shadow: inset rgb(0 0 0 / 40%) 0px 20px 15px -20px,
    inset rgb(0 0 0 / 40%) 0px -20px 15px -20px;
  color: #fff;
  padding: 2.5rem 0 2rem 0;
  margin-bottom: -2em;
`;

const StyledField = styled(Field)`
  label {
    margin-bottom: 0.15rem;
  }

  & > div {
    padding-bottom: 1em;
  }
`;

const ContactForm = ({ locale }: { locale: string }) => {
  const toast = useToast();

  function validateEmail(value: string) {
    let error;
    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  return (
    <StyledContactForm>
      <CenterWrapper>
        <Heading as="h2" size="lg" mb={4}>
          Kontakt
        </Heading>
        <Formik
          initialValues={{
            email: "",
            firstname: "",
            lastname: "",
          }}
          onSubmit={
            (values, actions) => {
              fetch("/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: encode({ "form-name": "contact", ...values }),
              })
                .then(() => {
                  toast({
                    title: "Anfrage abgesendet.",
                    description: "Ihre Anfrage wurde erfolgreich versendet.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                  });
                  actions.resetForm();
                })
                .catch(() => {
                  toast({
                    title: "Fehler beim Senden.",
                    description:
                      "Ihre Anfrage konnte nicht versendet werden. Bitte versuchen Sie es später erneut.",
                    status: "error",
                    duration: 10000,
                    isClosable: true,
                  });
                })
                .finally(() => actions.setSubmitting(false));
            }
            // async ({ email, firstname, lastname }, actions) => {
            // const result = await addToMailchimp(email, {
            //   FNAME: firstname,
            //   LNAME: lastname,
            //   tags: 7501830,
            // });
            // actions.setSubmitting(false);
            // I recommend setting `result` to React state
            // but you can do whatever you want
            // }
          }
        >
          {(props) => (
            <Form name="contact" data-netlify={true}>
              <StyledField name="email" validate={validateEmail}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel>E-Mail Adresse</FormLabel>
                    <Input {...field} />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </StyledField>
              <StyledField name="firstname">
                {({ field, form }) => (
                  <FormControl>
                    <FormLabel>Vorname</FormLabel>
                    <Input {...field} />
                  </FormControl>
                )}
              </StyledField>
              <StyledField name="lastname">
                {({ field, form }) => (
                  <FormControl>
                    <FormLabel>Nachname</FormLabel>
                    <Input {...field} />
                  </FormControl>
                )}
              </StyledField>
              <StyledField name="text">
                {({ field, form }) => (
                  <FormControl>
                    <FormLabel>Nachricht</FormLabel>
                    <Textarea {...field} />
                  </FormControl>
                )}
              </StyledField>
              <Button
                mt={4}
                colorScheme="teal"
                isLoading={props.isSubmitting}
                type="submit"
              >
                Abschicken
              </Button>
            </Form>
          )}
        </Formik>
      </CenterWrapper>
    </StyledContactForm>
  );
};

export default ContactForm;
