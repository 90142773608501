import * as React from "react";
import Slick from "react-slick";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import { Flex } from "@chakra-ui/react";
import { Link } from "gatsby";

import { HeroI } from "../interfaces";
import ScrollDown from "./ScrollDown";

const FullWidthSlickWrapper = styled.div`
  position: relative;

  .slick-list {
    transition: height 0.3s ease;
  }

  .slick-arrow {
    position: absolute;
    z-index: 10;

    &.slick-prev {
      left: 15px !important;
    }
    &.slick-next {
      right: 15px !important;
    }
    &.slick-prev:before,
    &.slick-next:before {
      color: #ffb45c;
      text-shadow: 0px 0px 3px #000000b8;
    }
  }
`;

const Meta = styled(Flex)`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 3em;
  color: #fff;
  text-shadow: #000 0 0 3px; */
`;

const Title = styled.h2`
  font-size: 2.25rem;
  font-weight: bold;
`;

const Copyright = styled.div`
  font-size: 0.8rem;
  color: #0009;
  padding: 0.2em 0.4em;
  -webkit-text-stroke: none;
  text-shadow: none;
`;

const Slide = styled.div`
  position: relative;

  width: 100vw;
  height: 75vh;

  @media (min-aspect-ratio: 1/1) {
    height: 100vh;
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100vw;
  height: 75vh;

  @media (min-aspect-ratio: 1/1) {
    height: 100vh;
  }
`;

const HeroSlider = ({ elements, time = 6, locale }: HeroI) => {
  const [userControlled, setUserControlled] = React.useState(false);

  return (
    <FullWidthSlickWrapper>
      <Slick
        arrows
        autoplay={true}
        dots
        // infinite
        lazyLoad="progressive"
        onSwipe={() => setUserControlled(true)}
        pauseOnHover
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        autoplaySpeed={time * 1000}
      >
        {elements.map(
          ({ link, title, heroElementMedia, workMedia, exhibitionMedia }) => {
            let media, description, videoUrl;
            if (heroElementMedia?.file) {
              if (
                (heroElementMedia?.file?.contentType ?? "").includes("video")
              ) {
                videoUrl = heroElementMedia?.file?.url;
              } else {
                media = heroElementMedia.gatsbyImageData;
                description = heroElementMedia.description;
              }
            } else if (workMedia?.length) {
              media = workMedia[0].gatsbyImageData;
              description = workMedia[0].description;
            } else if (exhibitionMedia?.length) {
              media = exhibitionMedia[0].gatsbyImageData;
              description = exhibitionMedia[0].description;
            } else {
              return null;
            }

            return (
              <React.Fragment key={title}>
                <Slide>
                  {videoUrl ? (
                    <video
                      src={videoUrl}
                      autoPlay
                      muted
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <StyledGatsbyImage
                      image={getImage(media!)!}
                      alt={title ? title : ""}
                      // objectFit="fill"
                    />
                  )}
                  <Meta justifyContent="space-between" alignItems="baseline">
                    {title && link && link.slug ? (
                      <Link to={`/${locale}/${link.slug}`}>
                        <Title>{title}</Title>
                      </Link>
                    ) : (
                      <Title>{title}</Title>
                    )}
                    {description && <Copyright>{description}</Copyright>}
                  </Meta>
                </Slide>
              </React.Fragment>
            );
          }
        )}
      </Slick>
      <ScrollDown />
    </FullWidthSlickWrapper>
  );
};

export default HeroSlider;
