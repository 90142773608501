import * as React from "react";
import styled from "@emotion/styled";
import { scroller, Element as ScrollElement } from "react-scroll";

const ScrollDownWrapper = styled.div`
  a {
    position: absolute;
    bottom: -4em;
    left: 50%;
    z-index: 2;
    display: inline-block;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: #fff;
    letter-spacing: 0.1em;
    text-decoration: none;
    transition: opacity 0.3s;
    cursor: pointer;
  }
  a div {
    padding: 4em;
    margin-left: -4em;
  }
  a span {
    position: absolute;
    bottom: 33%;
    left: 0;
    width: 2em;
    height: 2em;
    margin-left: -1em;
    border-left: 2px solid #ffb45c;
    border-bottom: 2px solid #ffb45c;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb04 2s infinite;
    animation: sdb04 2s infinite;
    box-sizing: border-box;
  }
  @-webkit-keyframes sdb04 {
    0% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
    }
    20% {
      -webkit-transform: rotate(-45deg) translate(-10px, 10px);
    }
    40% {
      -webkit-transform: rotate(-45deg) translate(0, 0);
    }
  }
  @keyframes sdb04 {
    0% {
      transform: rotate(-45deg) translate(0, 0);
    }
    20% {
      transform: rotate(-45deg) translate(-10px, 10px);
    }
    40% {
      transform: rotate(-45deg) translate(0, 0);
    }
  }
`;

const ScrollDown = () => {
  return (
    <ScrollDownWrapper>
      <a
        onClick={(e) => {
          e.preventDefault();
          scroller.scrollTo("animation-bottom", {
            duration: 800,
            delay: 0,
            offset: -120,
            smooth: "easeInOutQuart",
          });
        }}
      >
        <div>
          <span />
        </div>
      </a>
      <ScrollElement name="animation-bottom" />
    </ScrollDownWrapper>
  );
};

export default ScrollDown;
