import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { Box, Grid, GridItem, SimpleGrid } from "@chakra-ui/react";

import Layout from "../components/Layout";
import HeroSlider from "../components/HeroSilder";
import CenterWrapper from "../components/CenterWrapper";
import ExhibitionSection from "../components/ExhibitionSection";
import NewsletterSignUp from "../components/NewsletterSignUp";
import ImageZoom from "../components/ImageZoom";
import ContactForm from "../components/ContactForm";

import { ExhibitionI, PageI, WorkI } from "../interfaces";
import documentToReactComponentsCustom from "../ctfTextToReact";

const Page = ({
  data: {
    contentfulPage: { node_locale, title, description, slug, content },
    allContentfulWork,
    allContentfulExhibition,
  },
  pageContext: { language, locale, localeSwitcher },
  location,
}: {
  data: {
    contentfulPage: PageI;
    allContentfulWork: { nodes: WorkI[] };
    allContentfulExhibition: { nodes: ExhibitionI[] };
  };
  pageContext: {
    title: string;
    locale: string;
    language: string;
    localeSwitcher: { [key: string]: string };
  };
  location: { pathname: string };
}) => {
  const works = allContentfulWork.nodes;
  const exhibitions = allContentfulExhibition.nodes;

  return (
    <Layout
      title={title}
      description={description}
      locale={node_locale}
      location={location}
      localeSwitcher={localeSwitcher}
    >
      {content?.map((element, key) => {
        const {
          internal: { type },
        } = element;
        if (type === "ContentfulPageHero") {
          const { elements, time } = element; // interface fehlt noch
          return (
            <div key={key} style={{ marginTop: "-8em" }}>
              <HeroSlider elements={elements} time={time} locale={locale} />
            </div>
          );
        }
        if (type === "ContentfulPagePlaceholder") {
          if (element.type === "Social Media") {
            return <CenterWrapper key={key}>SOCIAL MEDIA</CenterWrapper>;
          }
          if (element.type === "Newsletter") {
            return <NewsletterSignUp key={key} locale={locale} />;
          }
          if (element.type === "Contact Form") {
            return <ContactForm key={key} locale={locale} />;
          }
          if (element.type === "Exhibition Slider") {
            return (
              <ExhibitionSection
                exhibitions={exhibitions}
                locale={locale}
                key={key}
              />
            );
          }
          return <></>;
        }
        if (type === "ContentfulPageColumns") {
          return (
            <>
              {element?.content?.map(({ text, file }, index) => {
                if (text && text.raw)
                  return (
                    <Box key={index}>
                      {documentToReactComponentsCustom(text)}
                    </Box>
                  );
                if (file?.gatsbyImageData)
                  return (
                    <Box key={index} width={"33%"} float="right">
                      <ImageZoom
                        image={getImage(file.gatsbyImageData)!}
                        alt={title}
                        description={file.description}
                      />
                    </Box>
                  );
                return <GridItem key={index}>EMPTY</GridItem>;
              })}
            </>
          );
        }
        if (element?.text?.raw) {
          return (
            <CenterWrapper key={key} padding="2em 0 0 0">
              {documentToReactComponentsCustom(element.text)}
            </CenterWrapper>
          );
        }
      })}
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query PageQuery($contentful_id: String!, $language: String!) {
    contentfulPage(
      contentful_id: { eq: $contentful_id }
      node_locale: { eq: $language }
    ) {
      ...ContentfulPageFragment
    }

    allContentfulExhibition(
      filter: {
        node_locale: { eq: $language }
        title: { ne: "DummyExhibition" }
      }
    ) {
      nodes {
        ...ContentfulExhibitionFragment
      }
    }

    allContentfulWork(
      filter: { node_locale: { eq: $language }, title: { ne: "DummyWork" } }
    ) {
      nodes {
        ...ContentfulWorkFragment
      }
    }
  }
`;
