import * as React from "react";
import styled from "@emotion/styled";
import { Flex, Heading } from "@chakra-ui/react";
import { ArrowRightIcon } from "@chakra-ui/icons";
import { Link } from "gatsby";

import CenterWrapper from "./CenterWrapper";
import Slider from "./Slider";
import ExhibitionCard from "./ExhibitionCard";

import { ExhibitionI } from "../interfaces";

const ExhibitionSectionWrapper = styled.div`
  min-height: 33vh;
  background-color: #585858;
  box-shadow: inset rgb(0 0 0 / 40%) 0px 20px 15px -20px,
    inset rgb(0 0 0 / 40%) 0px -20px 15px -20px;
  padding: 2.5em 0;

  @media (max-width: 768px) {
  }
`;

const ExhibitionSliderWrapper = styled.div`
  width: 100%;

  @media (min-width: 420px) {
    width: 75%;
  }
`;

const ExhibitionMore = styled.div`
  padding-top: 3.5em;
  color: #fff;
`;

const ExhibitionSection = ({
  exhibitions,
  locale,
}: {
  exhibitions: ExhibitionI[];
  locale: string;
}) => {
  const allExhibitions = exhibitions;

  // Filter out exhibitions that are not in the future or current
  exhibitions = exhibitions?.filter(
    ({ dateEnd }) => new Date(dateEnd) >= new Date()
  );

  // Sort exhibitions by start date, ascending
  exhibitions = exhibitions?.sort(
    (a, b) => new Date(b.dateStart).getTime() - new Date(a.dateStart).getTime()
  );
  // TODO: Sort by end date, descending (in case all have started already)
  // exhibitions = exhibitions?.sort(
  //   (a, b) => new Date(a.dateEnd).getTime() - new Date(b.dateEnd).getTime()
  // );

  // Make the last exhibition show up, even if it's in the past
  if (exhibitions.length === 0) {
    exhibitions = allExhibitions?.sort(
      (a, b) => new Date(a.dateStart).getTime() - new Date(b.dateStart).getTime()
    );
    exhibitions = [allExhibitions[allExhibitions.length - 1]];
  }

  return (
    <ExhibitionSectionWrapper>
      <CenterWrapper>
        <Heading as="h2" size="xl" color="white" marginBottom="0.66em">
          {locale === "de" ? "Ausstellungen" : "Exhibitions"}
        </Heading>
        <Flex align="end" justify="space-between" w="100%" wrap="wrap">
          <ExhibitionSliderWrapper>
            <Slider
              data={exhibitions}
              locale={locale}
              loop={false}
              slideElement={(props) => (
                <ExhibitionCard showBackground={false} textColor="#fff" {...props} />
              )}
            />
          </ExhibitionSliderWrapper>
          <ExhibitionMore>
            {locale === "de" ? (
              <Link to="/de/ausstellungen">
                Vergangene Ausstellungen <ArrowRightIcon />
              </Link>
            ) : (
              <Link to="/en/exhibitions">
                Past exhibitions
                <ArrowRightIcon />
              </Link>
            )}
          </ExhibitionMore>
        </Flex>
      </CenterWrapper>
    </ExhibitionSectionWrapper>
  );
};

export default ExhibitionSection;
